import "../assets/css/homepage.scss";
import logo from "../assets/images/logo.jpg";
import instagram from "../assets/images/instagram-color-svgrepo-com.svg";
import jg from "../assets/images/logo_JG1.png";


const Homepage = () => {

    return (
        <>
            <div className="container">                
                <img src={logo} alt="Logo du Domaine de Couite - Sauternes"  className="logo"/>
                <div className="chai">
                </div>
                 <div className="vignes">
                    <div className="article">
                        <h1>Domaine de Couite</h1>
                        <div className="presentation">
                            <p>Bientôt 100 ans d'histoire</p>
                            <p>Ouverture prochaine de notre site</p>
                            <p>Pour plus d'informations : <h4><a href="mailto:info@domainedecouite.com?subject=Prise de contact" className="a">info@domainedecouite.com</a></h4></p>
                            <a href="https://www.instagram.com/domainedecouite/" target="_blank" ><img src={instagram} alt="instagram Fabien Deloubes - Domaine de Couite"  className="instagram"/></a>
                        </div>
                    </div>  
                </div>
                <a href="https://overthere.fr" target="_blank"><img src={jg} alt="Jesse Granier - Développeur Web"  className="jg"/></a>
            </div>
        </>
    )
}

export default Homepage;